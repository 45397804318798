// ../../../react-shim.js
import React from "react";

// src/avatar.tsx
import {
  chakra as chakra4,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
var dataAttr = (condition) => condition ? "" : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/avatar-context.tsx
import { createContext } from "@chakra-ui/react-context";
var [AvatarStylesProvider, useAvatarStyles] = createContext({
  name: `AvatarStylesContext`,
  hookName: `useAvatarStyles`,
  providerName: "<Avatar/>"
});

// src/avatar-image.tsx
import { useImage } from "@chakra-ui/image";
import { chakra as chakra3 } from "@chakra-ui/system";
import { cloneElement } from "react";

// src/avatar-name.tsx
import { chakra } from "@chakra-ui/system";
function initials(name) {
  const [firstName, lastName] = name.split(" ");
  return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0);
}
function AvatarName(props) {
  const { name, getInitials, ...rest } = props;
  const styles = useAvatarStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    role: "img",
    "aria-label": name,
    ...rest,
    __css: styles.label
  }, name ? getInitials == null ? void 0 : getInitials(name) : null);
}
AvatarName.displayName = "AvatarName";

// src/generic-avatar-icon.tsx
import { chakra as chakra2 } from "@chakra-ui/system";
var GenericAvatarIcon = (props) => /* @__PURE__ */ React.createElement(chakra2.svg, {
  viewBox: "0 0 128 128",
  color: "#fff",
  width: "100%",
  height: "100%",
  className: "chakra-avatar__svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
}));

// src/avatar-image.tsx
function AvatarImage(props) {
  const {
    src,
    srcSet,
    onError,
    onLoad,
    getInitials,
    name,
    borderRadius,
    loading,
    iconLabel,
    icon = /* @__PURE__ */ React.createElement(GenericAvatarIcon, null),
    ignoreFallback,
    referrerPolicy
  } = props;
  const status = useImage({ src, onError, ignoreFallback });
  const hasLoaded = status === "loaded";
  const showFallback = !src || !hasLoaded;
  if (showFallback) {
    return name ? /* @__PURE__ */ React.createElement(AvatarName, {
      className: "chakra-avatar__initials",
      getInitials,
      name
    }) : cloneElement(icon, {
      role: "img",
      "aria-label": iconLabel
    });
  }
  return /* @__PURE__ */ React.createElement(chakra3.img, {
    src,
    srcSet,
    alt: name,
    onLoad,
    referrerPolicy,
    className: "chakra-avatar__img",
    loading,
    __css: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius
    }
  });
}
AvatarImage.displayName = "AvatarImage";

// src/avatar.tsx
import { useState } from "react";
var baseStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  textTransform: "uppercase",
  fontWeight: "medium",
  position: "relative",
  flexShrink: 0
};
var Avatar = forwardRef((props, ref) => {
  const styles = useMultiStyleConfig("Avatar", props);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    src,
    srcSet,
    name,
    showBorder,
    borderRadius = "full",
    onError,
    onLoad: onLoadProp,
    getInitials = initials,
    icon = /* @__PURE__ */ React.createElement(GenericAvatarIcon, null),
    iconLabel = " avatar",
    loading,
    children,
    borderColor,
    ignoreFallback,
    ...rest
  } = omitThemingProps(props);
  const avatarStyles = {
    borderRadius,
    borderWidth: showBorder ? "2px" : void 0,
    ...baseStyle,
    ...styles.container
  };
  if (borderColor) {
    avatarStyles.borderColor = borderColor;
  }
  return /* @__PURE__ */ React.createElement(chakra4.span, {
    ref,
    ...rest,
    className: cx("chakra-avatar", props.className),
    "data-loaded": dataAttr(isLoaded),
    __css: avatarStyles
  }, /* @__PURE__ */ React.createElement(AvatarStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(AvatarImage, {
    src,
    srcSet,
    loading,
    onLoad: callAllHandlers(onLoadProp, () => {
      setIsLoaded(true);
    }),
    onError,
    getInitials,
    name,
    borderRadius,
    icon,
    iconLabel,
    ignoreFallback
  }), children));
});
Avatar.displayName = "Avatar";

// src/avatar-group.tsx
import {
  chakra as chakra5,
  forwardRef as forwardRef2,
  omitThemingProps as omitThemingProps2,
  useMultiStyleConfig as useMultiStyleConfig2
} from "@chakra-ui/system";
import { getValidChildren } from "@chakra-ui/react-children-utils";

// ../../utilities/object-utils/src/index.ts
function compact(object) {
  const clone = Object.assign({}, object);
  for (let key in clone) {
    if (clone[key] === void 0)
      delete clone[key];
  }
  return clone;
}

// src/avatar-group.tsx
import { cloneElement as cloneElement2 } from "react";
var AvatarGroup = forwardRef2(function AvatarGroup2(props, ref) {
  const styles = useMultiStyleConfig2("Avatar", props);
  const {
    children,
    borderColor,
    max,
    spacing = "-0.75rem",
    borderRadius = "full",
    ...rest
  } = omitThemingProps2(props);
  const validChildren = getValidChildren(children);
  const childrenWithinMax = max ? validChildren.slice(0, max) : validChildren;
  const excess = max != null && validChildren.length - max;
  const reversedChildren = childrenWithinMax.reverse();
  const clones = reversedChildren.map((child, index) => {
    const isFirstAvatar = index === 0;
    const childProps = {
      marginEnd: isFirstAvatar ? 0 : spacing,
      size: props.size,
      borderColor: child.props.borderColor ?? borderColor,
      showBorder: true
    };
    return cloneElement2(child, compact(childProps));
  });
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    ...styles.group
  };
  const excessStyles = {
    borderRadius,
    marginStart: spacing,
    ...baseStyle,
    ...styles.excessLabel
  };
  return /* @__PURE__ */ React.createElement(chakra5.div, {
    ref,
    role: "group",
    __css: groupStyles,
    ...rest,
    className: cx("chakra-avatar__group", props.className)
  }, excess > 0 && /* @__PURE__ */ React.createElement(chakra5.span, {
    className: "chakra-avatar__excess",
    __css: excessStyles
  }, `+${excess}`), clones);
});
AvatarGroup.displayName = "AvatarGroup";

// src/avatar-badge.tsx
import {
  chakra as chakra6,
  forwardRef as forwardRef3
} from "@chakra-ui/system";
var placementMap = {
  "top-start": {
    top: "0",
    insetStart: "0",
    transform: "translate(-25%, -25%)"
  },
  "top-end": {
    top: "0",
    insetEnd: "0",
    transform: "translate(25%, -25%)"
  },
  "bottom-start": {
    bottom: "0",
    insetStart: "0",
    transform: "translate(-25%, 25%)"
  },
  "bottom-end": {
    bottom: "0",
    insetEnd: "0",
    transform: "translate(25%, 25%)"
  }
};
var AvatarBadge = forwardRef3(function AvatarBadge2(props, ref) {
  const { placement = "bottom-end", className, ...rest } = props;
  const styles = useAvatarStyles();
  const placementStyles = placementMap[placement];
  const badgeStyles = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...placementStyles,
    ...styles.badge
  };
  return /* @__PURE__ */ React.createElement(chakra6.div, {
    ref,
    ...rest,
    className: cx("chakra-avatar__badge", className),
    __css: badgeStyles
  });
});
AvatarBadge.displayName = "AvatarBadge";
export {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  GenericAvatarIcon,
  useAvatarStyles
};
