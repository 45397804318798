// ../../../react-shim.js
import React from "react";

// src/spinner.tsx
import {
  chakra,
  forwardRef,
  keyframes,
  omitThemingProps,
  useStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/spinner.tsx
var spin = keyframes({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
});
var Spinner = forwardRef((props, ref) => {
  const styles = useStyleConfig("Spinner", props);
  const {
    label = "Loading...",
    thickness = "2px",
    speed = "0.45s",
    emptyColor = "transparent",
    className,
    ...rest
  } = omitThemingProps(props);
  const _className = cx("chakra-spinner", className);
  const spinnerStyles = {
    display: "inline-block",
    borderColor: "currentColor",
    borderStyle: "solid",
    borderRadius: "99999px",
    borderWidth: thickness,
    borderBottomColor: emptyColor,
    borderLeftColor: emptyColor,
    animation: `${spin} ${speed} linear infinite`,
    ...styles
  };
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    __css: spinnerStyles,
    className: _className,
    ...rest
  }, label && /* @__PURE__ */ React.createElement(chakra.span, {
    srOnly: true
  }, label));
});
Spinner.displayName = "Spinner";
export {
  Spinner
};
