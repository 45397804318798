// src/use-pan-event.ts
import { addPointerEvent as addPointerEvent2 } from "@chakra-ui/event-utils";
import { useLatestRef } from "@chakra-ui/react-use-latest-ref";
import { useEffect, useRef } from "react";

// src/pan-event.ts
import {
  addPointerEvent,
  getEventPoint,
  isMultiTouchEvent
} from "@chakra-ui/event-utils";
import sync, { cancelSync, getFrameData } from "framesync";
var PanEvent = class {
  history = [];
  startEvent = null;
  lastEvent = null;
  lastEventInfo = null;
  handlers = {};
  removeListeners = () => {
  };
  threshold = 3;
  win;
  constructor(event, handlers, threshold) {
    this.win = event.view ?? window;
    if (isMultiTouchEvent(event))
      return;
    this.handlers = handlers;
    if (threshold) {
      this.threshold = threshold;
    }
    event.stopPropagation();
    event.preventDefault();
    const info = { point: getEventPoint(event) };
    const { timestamp } = getFrameData();
    this.history = [{ ...info.point, timestamp }];
    const { onSessionStart } = handlers;
    onSessionStart == null ? void 0 : onSessionStart(event, getPanInfo(info, this.history));
    this.removeListeners = pipe(addPointerEvent(this.win, "pointermove", this.onPointerMove), addPointerEvent(this.win, "pointerup", this.onPointerUp), addPointerEvent(this.win, "pointercancel", this.onPointerUp));
  }
  updatePoint = () => {
    if (!(this.lastEvent && this.lastEventInfo))
      return;
    const info = getPanInfo(this.lastEventInfo, this.history);
    const isPanStarted = this.startEvent !== null;
    const isDistancePastThreshold = distance(info.offset, { x: 0, y: 0 }) >= this.threshold;
    if (!isPanStarted && !isDistancePastThreshold)
      return;
    const { timestamp } = getFrameData();
    this.history.push({ ...info.point, timestamp });
    const { onStart, onMove } = this.handlers;
    if (!isPanStarted) {
      onStart == null ? void 0 : onStart(this.lastEvent, info);
      this.startEvent = this.lastEvent;
    }
    onMove == null ? void 0 : onMove(this.lastEvent, info);
  };
  onPointerMove = (event, info) => {
    this.lastEvent = event;
    this.lastEventInfo = info;
    sync.update(this.updatePoint, true);
  };
  onPointerUp = (event, info) => {
    const panInfo = getPanInfo(info, this.history);
    const { onEnd, onSessionEnd } = this.handlers;
    onSessionEnd == null ? void 0 : onSessionEnd(event, panInfo);
    this.end();
    if (!onEnd || !this.startEvent)
      return;
    onEnd == null ? void 0 : onEnd(event, panInfo);
  };
  updateHandlers(handlers) {
    this.handlers = handlers;
  }
  end() {
    var _a;
    (_a = this.removeListeners) == null ? void 0 : _a.call(this);
    cancelSync.update(this.updatePoint);
  }
};
function subtract(a, b) {
  return { x: a.x - b.x, y: a.y - b.y };
}
function getPanInfo(info, history) {
  return {
    point: info.point,
    delta: subtract(info.point, history[history.length - 1]),
    offset: subtract(info.point, history[0]),
    velocity: getVelocity(history, 0.1)
  };
}
var toMilliseconds = (v) => v * 1e3;
function getVelocity(history, timeDelta) {
  if (history.length < 2) {
    return { x: 0, y: 0 };
  }
  let i = history.length - 1;
  let timestampedPoint = null;
  const lastPoint = history[history.length - 1];
  while (i >= 0) {
    timestampedPoint = history[i];
    if (lastPoint.timestamp - timestampedPoint.timestamp > toMilliseconds(timeDelta)) {
      break;
    }
    i--;
  }
  if (!timestampedPoint) {
    return { x: 0, y: 0 };
  }
  const time = (lastPoint.timestamp - timestampedPoint.timestamp) / 1e3;
  if (time === 0) {
    return { x: 0, y: 0 };
  }
  const currentVelocity = {
    x: (lastPoint.x - timestampedPoint.x) / time,
    y: (lastPoint.y - timestampedPoint.y) / time
  };
  if (currentVelocity.x === Infinity) {
    currentVelocity.x = 0;
  }
  if (currentVelocity.y === Infinity) {
    currentVelocity.y = 0;
  }
  return currentVelocity;
}
function pipe(...fns) {
  return (v) => fns.reduce((a, b) => b(a), v);
}
function distance1D(a, b) {
  return Math.abs(a - b);
}
function isPoint(point) {
  return "x" in point && "y" in point;
}
function distance(a, b) {
  if (typeof a === "number" && typeof b === "number") {
    return distance1D(a, b);
  }
  if (isPoint(a) && isPoint(b)) {
    const xDelta = distance1D(a.x, b.x);
    const yDelta = distance1D(a.y, b.y);
    return Math.sqrt(xDelta ** 2 + yDelta ** 2);
  }
  return 0;
}

// src/use-pan-event.ts
function usePanEvent(ref, options) {
  const {
    onPan,
    onPanStart,
    onPanEnd,
    onPanSessionStart,
    onPanSessionEnd,
    threshold
  } = options;
  const hasPanEvents = Boolean(onPan || onPanStart || onPanEnd || onPanSessionStart || onPanSessionEnd);
  const panSession = useRef(null);
  const handlersRef = useLatestRef({
    onSessionStart: onPanSessionStart,
    onSessionEnd: onPanSessionEnd,
    onStart: onPanStart,
    onMove: onPan,
    onEnd(event, info) {
      panSession.current = null;
      onPanEnd == null ? void 0 : onPanEnd(event, info);
    }
  });
  useEffect(() => {
    var _a;
    (_a = panSession.current) == null ? void 0 : _a.updateHandlers(handlersRef.current);
  });
  useEffect(() => {
    const node = ref.current;
    if (!node || !hasPanEvents)
      return;
    function onPointerDown(event) {
      panSession.current = new PanEvent(event, handlersRef.current, threshold);
    }
    return addPointerEvent2(node, "pointerdown", onPointerDown);
  }, [ref, hasPanEvents, handlersRef, threshold]);
  useEffect(() => {
    return () => {
      var _a;
      (_a = panSession.current) == null ? void 0 : _a.end();
      panSession.current = null;
    };
  }, []);
}
export {
  usePanEvent
};
