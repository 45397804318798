import { MODE, URLS } from './constants';
var loadedPaymentInstance = null;
export function RevolutPaymentsLoader(token, mode, locale) {
    if (mode === void 0) { mode = RevolutPaymentsLoader.mode; }
    if (loadedPaymentInstance) {
        var instance = loadedPaymentInstance({ publicToken: token, locale: locale });
        return Promise.resolve(instance);
    }
    var script = document.createElement('script');
    script.id = 'revolut-payments';
    script.src = URLS[mode];
    script.async = true;
    document.head.appendChild(script);
    return new Promise(function (resolve, reject) {
        function handleError() {
            document.head.removeChild(script);
            reject(new Error("'RevolutPayments' failed to load"));
        }
        function handleLoad() {
            if (typeof RevolutCheckout === 'function') {
                resolve(RevolutCheckout.payments({ publicToken: token, locale: locale }));
                loadedPaymentInstance = RevolutCheckout.payments;
                delete window.RevolutCheckout;
            }
            else {
                handleError();
            }
        }
        script.onload = handleLoad;
        script.onerror = handleError;
    });
}
RevolutPaymentsLoader.mode = MODE.PRODUCTION;
