// ../../../react-shim.js
import React from "react";

// src/env.tsx
import {
  createContext,
  startTransition,
  useContext,
  useMemo,
  useState,
  useEffect
} from "react";

// src/mock-document.ts
var doc = {
  body: {
    classList: {
      add() {
      },
      remove() {
      }
    }
  },
  addEventListener() {
  },
  removeEventListener() {
  },
  activeElement: {
    blur() {
    },
    nodeName: ""
  },
  querySelector() {
    return null;
  },
  querySelectorAll() {
    return [];
  },
  getElementById() {
    return null;
  },
  createEvent() {
    return {
      initEvent() {
      }
    };
  },
  createElement() {
    return {
      children: [],
      childNodes: [],
      style: {},
      setAttribute() {
      },
      getElementsByTagName() {
        return [];
      }
    };
  }
};
var ssrDocument = doc;

// src/mock-window.ts
var noop = () => {
};
var win = {
  document: ssrDocument,
  navigator: {
    userAgent: ""
  },
  CustomEvent: function CustomEvent() {
    return this;
  },
  addEventListener: noop,
  removeEventListener: noop,
  getComputedStyle() {
    return {
      getPropertyValue() {
        return "";
      }
    };
  },
  matchMedia() {
    return {
      matches: false,
      addListener: noop,
      removeListener: noop
    };
  },
  requestAnimationFrame(callback) {
    if (typeof setTimeout === "undefined") {
      callback();
      return null;
    }
    return setTimeout(callback, 0);
  },
  cancelAnimationFrame(id) {
    if (typeof setTimeout === "undefined")
      return;
    clearTimeout(id);
  },
  setTimeout: () => 0,
  clearTimeout: noop,
  setInterval: () => 0,
  clearInterval: noop
};
var ssrWindow = win;

// src/env.tsx
var mockEnv = {
  window: ssrWindow,
  document: ssrDocument
};
var defaultEnv = typeof window !== "undefined" ? { window, document } : mockEnv;
var EnvironmentContext = createContext(defaultEnv);
EnvironmentContext.displayName = "EnvironmentContext";
function useEnvironment() {
  return useContext(EnvironmentContext);
}
function EnvironmentProvider(props) {
  const { children, environment: environmentProp } = props;
  const [node, setNode] = useState(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  const context = useMemo(() => {
    if (environmentProp) {
      return environmentProp;
    }
    const doc2 = node == null ? void 0 : node.ownerDocument;
    const win2 = node == null ? void 0 : node.ownerDocument.defaultView;
    const env = doc2 ? { document: doc2, window: win2 } : defaultEnv;
    return env;
  }, [node, environmentProp]);
  return /* @__PURE__ */ React.createElement(EnvironmentContext.Provider, {
    value: context
  }, children, !environmentProp && mounted && /* @__PURE__ */ React.createElement("span", {
    id: "__chakra_env",
    hidden: true,
    ref: (el) => {
      startTransition(() => {
        if (el)
          setNode(el);
      });
    }
  }));
}
EnvironmentProvider.displayName = "EnvironmentProvider";
export {
  EnvironmentProvider,
  useEnvironment
};
