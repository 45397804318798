// ../../../react-shim.js
import React from "react";

// src/textarea.tsx
import { useFormControl } from "@chakra-ui/form-control";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// ../../utilities/object-utils/src/index.ts
function omit(object, keysToOmit = []) {
  const clone = Object.assign({}, object);
  for (const key of keysToOmit) {
    if (key in clone)
      delete clone[key];
  }
  return clone;
}

// src/textarea.tsx
var omitted = ["h", "minH", "height", "minHeight"];
var Textarea = forwardRef((props, ref) => {
  const styles = useStyleConfig("Textarea", props);
  const { className, rows, ...rest } = omitThemingProps(props);
  const textareaProps = useFormControl(rest);
  const textareaStyles = rows ? omit(styles, omitted) : styles;
  return /* @__PURE__ */ React.createElement(chakra.textarea, {
    ref,
    rows,
    ...textareaProps,
    className: cx("chakra-textarea", className),
    __css: textareaStyles
  });
});
Textarea.displayName = "Textarea";
export {
  Textarea
};
