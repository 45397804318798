// src/use-animation-state.ts
import { getOwnerWindow } from "@chakra-ui/utils";
import { useEffect as useEffect3, useState } from "react";

// src/use-event-listener.ts
import { runIfFn } from "@chakra-ui/utils";
import { useEffect as useEffect2 } from "react";

// src/use-callback-ref.ts
import { useCallback, useRef } from "react";

// src/use-safe-layout-effect.ts
import { isBrowser } from "@chakra-ui/utils";
import { useEffect, useLayoutEffect } from "react";
var useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

// src/use-callback-ref.ts
function useCallbackRef(fn, deps = []) {
  const ref = useRef(fn);
  useSafeLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => {
    var _a;
    return (_a = ref.current) == null ? void 0 : _a.call(ref, ...args);
  }, deps);
}

// src/use-event-listener.ts
function useEventListener(event, handler, env, options) {
  const listener = useCallbackRef(handler);
  useEffect2(() => {
    const node = runIfFn(env) ?? document;
    if (!handler) {
      return;
    }
    node.addEventListener(event, listener, options);
    return () => {
      node.removeEventListener(event, listener, options);
    };
  }, [event, env, options, listener, handler]);
  return () => {
    const node = runIfFn(env) ?? document;
    node.removeEventListener(event, listener, options);
  };
}

// src/use-animation-state.ts
function useAnimationState(props) {
  const { isOpen, ref } = props;
  const [mounted, setMounted] = useState(isOpen);
  const [once, setOnce] = useState(false);
  useEffect3(() => {
    if (!once) {
      setMounted(isOpen);
      setOnce(true);
    }
  }, [isOpen, once, mounted]);
  useEventListener("animationend", () => {
    setMounted(isOpen);
  }, () => ref.current);
  const hidden = isOpen ? false : !mounted;
  return {
    present: !hidden,
    onComplete() {
      var _a;
      const win = getOwnerWindow(ref.current);
      const evt = new win.CustomEvent("animationend", { bubbles: true });
      (_a = ref.current) == null ? void 0 : _a.dispatchEvent(evt);
    }
  };
}

// src/use-boolean.ts
import { useMemo, useState as useState2 } from "react";
function useBoolean(initialState = false) {
  const [value, setValue] = useState2(initialState);
  const callbacks = useMemo(() => ({
    on: () => setValue(true),
    off: () => setValue(false),
    toggle: () => setValue((prev) => !prev)
  }), []);
  return [value, callbacks];
}

// src/use-clipboard.ts
import { useState as useState3, useCallback as useCallback2, useEffect as useEffect4 } from "react";
import copy from "copy-to-clipboard";
function useClipboard(initialValue, optionsOrTimeout = {}) {
  const [hasCopied, setHasCopied] = useState3(false);
  const [value, setValue] = useState3(initialValue);
  const { timeout = 1500, ...copyOptions } = typeof optionsOrTimeout === "number" ? { timeout: optionsOrTimeout } : optionsOrTimeout;
  const onCopy = useCallback2(() => {
    const didCopy = copy(value, copyOptions);
    setHasCopied(didCopy);
  }, [value, copyOptions]);
  useEffect4(() => {
    let timeoutId = null;
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);
  return { value, setValue, onCopy, hasCopied };
}

// src/use-const.ts
import { useRef as useRef2 } from "react";
function useConst(init) {
  const ref = useRef2(null);
  if (ref.current === null) {
    ref.current = typeof init === "function" ? init() : init;
  }
  return ref.current;
}

// src/use-controllable.ts
import { runIfFn as runIfFn2 } from "@chakra-ui/utils";
import { useCallback as useCallback3, useState as useState4 } from "react";
function useControllableProp(prop, state) {
  const isControlled = prop !== void 0;
  const value = isControlled && typeof prop !== "undefined" ? prop : state;
  return [isControlled, value];
}
function useControllableState(props) {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    shouldUpdate = (prev, next) => prev !== next
  } = props;
  const onChangeProp = useCallbackRef(onChange);
  const shouldUpdateProp = useCallbackRef(shouldUpdate);
  const [valueState, setValue] = useState4(defaultValue);
  const isControlled = valueProp !== void 0;
  const value = isControlled ? valueProp : valueState;
  const updateValue = useCallback3((next) => {
    const nextValue = runIfFn2(next, value);
    if (!shouldUpdateProp(value, nextValue)) {
      return;
    }
    if (!isControlled) {
      setValue(nextValue);
    }
    onChangeProp(nextValue);
  }, [isControlled, onChangeProp, value, shouldUpdateProp]);
  return [value, updateValue];
}

// src/use-dimensions.ts
import { getBox } from "@chakra-ui/utils";
import { useRef as useRef3, useState as useState5 } from "react";
function useDimensions(ref, observe) {
  const [dimensions, setDimensions] = useState5(null);
  const rafId = useRef3();
  useSafeLayoutEffect(() => {
    if (!ref.current)
      return void 0;
    const node = ref.current;
    function measure() {
      rafId.current = requestAnimationFrame(() => {
        const boxModel = getBox(node);
        setDimensions(boxModel);
      });
    }
    measure();
    if (observe) {
      window.addEventListener("resize", measure);
      window.addEventListener("scroll", measure);
    }
    return () => {
      if (observe) {
        window.removeEventListener("resize", measure);
        window.removeEventListener("scroll", measure);
      }
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [observe]);
  return dimensions;
}

// src/use-disclosure.ts
import { callAllHandlers } from "@chakra-ui/utils";

// src/use-id.ts
import { useCallback as useCallback4, useId as useReactId, useMemo as useMemo2, useState as useState6 } from "react";
function useId(idProp, prefix) {
  const id = useReactId();
  return useMemo2(() => idProp || [prefix, id].filter(Boolean).join("-"), [idProp, prefix, id]);
}
function useIds(idProp, ...prefixes) {
  const id = useId(idProp);
  return useMemo2(() => {
    return prefixes.map((prefix) => `${prefix}-${id}`);
  }, [id, prefixes]);
}
function useOptionalPart(partId) {
  const [id, setId] = useState6(null);
  const ref = useCallback4((node) => {
    setId(node ? partId : null);
  }, [partId]);
  return { ref, id, isRendered: Boolean(id) };
}

// src/use-disclosure.ts
import { useCallback as useCallback5, useState as useState7 } from "react";
function useDisclosure(props = {}) {
  const {
    onClose: onCloseProp,
    onOpen: onOpenProp,
    isOpen: isOpenProp,
    id: idProp
  } = props;
  const onOpenPropCallbackRef = useCallbackRef(onOpenProp);
  const onClosePropCallbackRef = useCallbackRef(onCloseProp);
  const [isOpenState, setIsOpen] = useState7(props.defaultIsOpen || false);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);
  const id = useId(idProp, "disclosure");
  const onClose = useCallback5(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onClosePropCallbackRef == null ? void 0 : onClosePropCallbackRef();
  }, [isControlled, onClosePropCallbackRef]);
  const onOpen = useCallback5(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenPropCallbackRef == null ? void 0 : onOpenPropCallbackRef();
  }, [isControlled, onOpenPropCallbackRef]);
  const onToggle = useCallback5(() => {
    const action = isOpen ? onClose : onOpen;
    action();
  }, [isOpen, onOpen, onClose]);
  return {
    isOpen: !!isOpen,
    onOpen,
    onClose,
    onToggle,
    isControlled,
    getButtonProps: (props2 = {}) => ({
      ...props2,
      "aria-expanded": isOpen,
      "aria-controls": id,
      onClick: callAllHandlers(props2.onClick, onToggle)
    }),
    getDisclosureProps: (props2 = {}) => ({
      ...props2,
      hidden: !isOpen,
      id
    })
  };
}

// src/use-event-listener-map.ts
import {
  getPointerEventName,
  wrapPointerEventHandler
} from "@chakra-ui/utils";
import { useCallback as useCallback6, useEffect as useEffect5, useRef as useRef4 } from "react";
function useEventListenerMap() {
  const listeners = useRef4(/* @__PURE__ */ new Map());
  const currentListeners = listeners.current;
  const add = useCallback6((el, type, listener, options) => {
    const pointerEventListener = wrapPointerEventHandler(listener, type === "pointerdown");
    listeners.current.set(listener, {
      __listener: pointerEventListener,
      type: getPointerEventName(type),
      el,
      options
    });
    el.addEventListener(type, pointerEventListener, options);
  }, []);
  const remove = useCallback6((el, type, listener, options) => {
    const { __listener: pointerEventListener } = listeners.current.get(listener);
    el.removeEventListener(type, pointerEventListener, options);
    listeners.current.delete(pointerEventListener);
  }, []);
  useEffect5(() => () => {
    currentListeners.forEach((value, key) => {
      remove(value.el, value.type, key, value.options);
    });
  }, [remove, currentListeners]);
  return { add, remove };
}

// src/use-focus-effect.ts
import { hasFocusWithin, focus } from "@chakra-ui/utils";

// src/use-update-effect.ts
import { useEffect as useEffect6, useRef as useRef5 } from "react";
var useUpdateEffect = (effect, deps) => {
  const renderCycleRef = useRef5(false);
  const effectCycleRef = useRef5(false);
  useEffect6(() => {
    const isMounted = renderCycleRef.current;
    const shouldRun = isMounted && effectCycleRef.current;
    if (shouldRun) {
      return effect();
    }
    effectCycleRef.current = true;
  }, deps);
  useEffect6(() => {
    renderCycleRef.current = true;
    return () => {
      renderCycleRef.current = false;
    };
  }, []);
};

// src/use-focus-effect.ts
function useFocusEffect(ref, options) {
  const { shouldFocus, preventScroll } = options;
  useUpdateEffect(() => {
    const node = ref.current;
    if (!node || !shouldFocus)
      return;
    if (!hasFocusWithin(node)) {
      focus(node, { preventScroll, nextTick: true });
    }
  }, [shouldFocus, ref, preventScroll]);
}

// src/use-focus-on-hide.ts
import {
  contains,
  focus as focus2,
  getActiveElement,
  isTabbable
} from "@chakra-ui/utils";
function preventReturnFocus(containerRef) {
  const el = containerRef.current;
  if (!el)
    return false;
  const activeElement = getActiveElement(el);
  if (!activeElement)
    return false;
  if (contains(el, activeElement))
    return false;
  if (isTabbable(activeElement))
    return true;
  return false;
}
function useFocusOnHide(containerRef, options) {
  const { shouldFocus: shouldFocusProp, visible, focusRef } = options;
  const shouldFocus = shouldFocusProp && !visible;
  useUpdateEffect(() => {
    if (!shouldFocus)
      return;
    if (preventReturnFocus(containerRef)) {
      return;
    }
    const el = (focusRef == null ? void 0 : focusRef.current) || containerRef.current;
    if (el) {
      focus2(el, { nextTick: true });
    }
  }, [shouldFocus, containerRef, focusRef]);
}

// src/use-focus-on-pointerdown.ts
import {
  contains as contains2,
  detectBrowser,
  focus as focus3,
  getOwnerDocument,
  isActiveElement,
  isRefObject
} from "@chakra-ui/utils";

// src/use-pointer-event.ts
import {
  getPointerEventName as getPointerEventName2,
  wrapPointerEventHandler as wrapPointerEventHandler2
} from "@chakra-ui/utils";
function usePointerEvent(env, eventName, handler, options) {
  return useEventListener(getPointerEventName2(eventName), wrapPointerEventHandler2(handler, eventName === "pointerdown"), env, options);
}

// src/use-focus-on-pointerdown.ts
function useFocusOnPointerDown(props) {
  const { ref, elements, enabled } = props;
  const isSafari = detectBrowser("Safari");
  const doc = () => getOwnerDocument(ref.current);
  usePointerEvent(doc, "pointerdown", (event) => {
    if (!isSafari || !enabled)
      return;
    const target = event.target;
    const els = elements ?? [ref];
    const isValidTarget = els.some((elementOrRef) => {
      const el = isRefObject(elementOrRef) ? elementOrRef.current : elementOrRef;
      return contains2(el, target);
    });
    if (!isActiveElement(target) && isValidTarget) {
      event.preventDefault();
      focus3(target);
    }
  });
}

// src/use-focus-on-show.ts
import {
  contains as contains3,
  focus as focus4,
  getAllFocusable,
  isRefObject as isRefObject2
} from "@chakra-ui/utils";
import { useCallback as useCallback7, useRef as useRef6 } from "react";
var defaultOptions = {
  preventScroll: true,
  shouldFocus: false
};
function useFocusOnShow(target, options = defaultOptions) {
  const { focusRef, preventScroll, shouldFocus, visible } = options;
  const element = isRefObject2(target) ? target.current : target;
  const autoFocusValue = shouldFocus && visible;
  const autoFocusRef = useRef6(autoFocusValue);
  const lastVisibleRef = useRef6(visible);
  useSafeLayoutEffect(() => {
    if (!lastVisibleRef.current && visible) {
      autoFocusRef.current = autoFocusValue;
    }
    lastVisibleRef.current = visible;
  }, [visible, autoFocusValue]);
  const onFocus = useCallback7(() => {
    if (!visible || !element || !autoFocusRef.current)
      return;
    autoFocusRef.current = false;
    if (contains3(element, document.activeElement))
      return;
    if (focusRef == null ? void 0 : focusRef.current) {
      focus4(focusRef.current, { preventScroll, nextTick: true });
    } else {
      const tabbableEls = getAllFocusable(element);
      if (tabbableEls.length > 0) {
        focus4(tabbableEls[0], { preventScroll, nextTick: true });
      }
    }
  }, [visible, preventScroll, element, focusRef]);
  useUpdateEffect(() => {
    onFocus();
  }, [onFocus]);
  useEventListener("transitionend", onFocus, element);
}

// src/use-force-update.ts
import { useCallback as useCallback8, useRef as useRef7, useState as useState8 } from "react";

// src/use-unmount-effect.ts
import { useEffect as useEffect7 } from "react";
function useUnmountEffect(fn, deps = []) {
  return useEffect7(() => () => fn(), deps);
}

// src/use-force-update.ts
function useForceUpdate() {
  const unloadingRef = useRef7(false);
  const [count, setCount] = useState8(0);
  useUnmountEffect(() => {
    unloadingRef.current = true;
  });
  return useCallback8(() => {
    if (!unloadingRef.current) {
      setCount(count + 1);
    }
  }, [count]);
}

// src/use-interval.ts
import { useEffect as useEffect8 } from "react";
function useInterval(callback, delay) {
  const fn = useCallbackRef(callback);
  useEffect8(() => {
    let intervalId = null;
    const tick = () => fn();
    if (delay !== null) {
      intervalId = window.setInterval(tick, delay);
    }
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [delay, fn]);
}

// src/use-latest-ref.ts
import { useRef as useRef8 } from "react";
function useLatestRef(value) {
  const ref = useRef8(null);
  ref.current = value;
  return ref;
}

// src/use-merge-refs.ts
import { useMemo as useMemo3 } from "react";
function assignRef(ref, value) {
  if (ref == null)
    return;
  if (typeof ref === "function") {
    ref(value);
    return;
  }
  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}
function useMergeRefs(...refs) {
  return useMemo3(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }
    return (node) => {
      refs.forEach((ref) => {
        if (ref)
          assignRef(ref, node);
      });
    };
  }, refs);
}

// src/use-mouse-down-ref.ts
import { useRef as useRef9 } from "react";
function useMouseDownRef(shouldListen = true) {
  const mouseDownRef = useRef9();
  useEventListener("mousedown", (event) => {
    if (shouldListen) {
      mouseDownRef.current = event.target;
    }
  });
  return mouseDownRef;
}

// src/use-outside-click.ts
import { getOwnerDocument as getOwnerDocument2 } from "@chakra-ui/utils";
import { useEffect as useEffect9, useRef as useRef10 } from "react";
function useOutsideClick(props) {
  const { ref, handler, enabled = true } = props;
  const savedHandler = useCallbackRef(handler);
  const stateRef = useRef10({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false
  });
  const state = stateRef.current;
  useEffect9(() => {
    if (!enabled)
      return;
    const onPointerDown = (e) => {
      if (isValidEvent(e, ref)) {
        state.isPointerDown = true;
      }
    };
    const onMouseUp = (event) => {
      if (state.ignoreEmulatedMouseEvents) {
        state.ignoreEmulatedMouseEvents = false;
        return;
      }
      if (state.isPointerDown && handler && isValidEvent(event, ref)) {
        state.isPointerDown = false;
        savedHandler(event);
      }
    };
    const onTouchEnd = (event) => {
      state.ignoreEmulatedMouseEvents = true;
      if (handler && state.isPointerDown && isValidEvent(event, ref)) {
        state.isPointerDown = false;
        savedHandler(event);
      }
    };
    const doc = getOwnerDocument2(ref.current);
    doc.addEventListener("mousedown", onPointerDown, true);
    doc.addEventListener("mouseup", onMouseUp, true);
    doc.addEventListener("touchstart", onPointerDown, true);
    doc.addEventListener("touchend", onTouchEnd, true);
    return () => {
      doc.removeEventListener("mousedown", onPointerDown, true);
      doc.removeEventListener("mouseup", onMouseUp, true);
      doc.removeEventListener("touchstart", onPointerDown, true);
      doc.removeEventListener("touchend", onTouchEnd, true);
    };
  }, [handler, ref, savedHandler, state, enabled]);
}
function isValidEvent(event, ref) {
  var _a;
  const target = event.target;
  if (event.button > 0)
    return false;
  if (target) {
    const doc = getOwnerDocument2(target);
    if (!doc.contains(target))
      return false;
  }
  return !((_a = ref.current) == null ? void 0 : _a.contains(target));
}

// src/use-pan-gesture.ts
import {
  noop,
  PanSession
} from "@chakra-ui/utils";
import { useEffect as useEffect10, useRef as useRef11 } from "react";
function usePanGesture(ref, props) {
  const {
    onPan,
    onPanStart,
    onPanEnd,
    onPanSessionStart,
    onPanSessionEnd,
    threshold
  } = props;
  const hasPanEvents = Boolean(onPan || onPanStart || onPanEnd || onPanSessionStart || onPanSessionEnd);
  const panSession = useRef11(null);
  const handlers = {
    onSessionStart: onPanSessionStart,
    onSessionEnd: onPanSessionEnd,
    onStart: onPanStart,
    onMove: onPan,
    onEnd(event, info) {
      panSession.current = null;
      onPanEnd == null ? void 0 : onPanEnd(event, info);
    }
  };
  useEffect10(() => {
    var _a;
    (_a = panSession.current) == null ? void 0 : _a.updateHandlers(handlers);
  });
  function onPointerDown(event) {
    panSession.current = new PanSession(event, handlers, threshold);
  }
  usePointerEvent(() => ref.current, "pointerdown", hasPanEvents ? onPointerDown : noop);
  useUnmountEffect(() => {
    var _a;
    (_a = panSession.current) == null ? void 0 : _a.end();
    panSession.current = null;
  });
}

// src/use-previous.ts
import { useRef as useRef12, useEffect as useEffect11 } from "react";
function usePrevious(value) {
  const ref = useRef12();
  useEffect11(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// src/use-shortcut.ts
import { useEffect as useEffect12, useRef as useRef13, useState as useState9 } from "react";
function isPrintableCharacter(event) {
  const { key } = event;
  return key.length === 1 || key.length > 1 && /[^a-zA-Z0-9]/.test(key);
}
function useShortcut(props = {}) {
  const { timeout = 300, preventDefault = () => true } = props;
  const [keys, setKeys] = useState9([]);
  const timeoutRef = useRef13();
  const flush = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };
  const clearKeysAfterDelay = () => {
    flush();
    timeoutRef.current = setTimeout(() => {
      setKeys([]);
      timeoutRef.current = null;
    }, timeout);
  };
  useEffect12(() => flush, []);
  function onKeyDown(fn) {
    return (event) => {
      if (event.key === "Backspace") {
        const keysCopy = [...keys];
        keysCopy.pop();
        setKeys(keysCopy);
        return;
      }
      if (isPrintableCharacter(event)) {
        const keysCopy = keys.concat(event.key);
        if (preventDefault(event)) {
          event.preventDefault();
          event.stopPropagation();
        }
        setKeys(keysCopy);
        fn(keysCopy.join(""));
        clearKeysAfterDelay();
      }
    };
  }
  return onKeyDown;
}

// src/use-timeout.ts
import { useEffect as useEffect13 } from "react";
function useTimeout(callback, delay) {
  const fn = useCallbackRef(callback);
  useEffect13(() => {
    if (delay == null)
      return void 0;
    let timeoutId = null;
    timeoutId = window.setTimeout(() => {
      fn();
    }, delay);
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [delay, fn]);
}

// src/use-why-update.ts
import { useEffect as useEffect14, useRef as useRef14 } from "react";
function useWhyDidYouUpdate(name, props) {
  const previousProps = useRef14();
  useEffect14(() => {
    if (previousProps.current) {
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      const changesObj = {};
      allKeys.forEach((key) => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    previousProps.current = props;
  });
}
export {
  assignRef,
  useAnimationState,
  useBoolean,
  useCallbackRef,
  useClipboard,
  useConst,
  useControllableProp,
  useControllableState,
  useDimensions,
  useDisclosure,
  useEventListener,
  useEventListenerMap,
  useFocusEffect,
  useFocusOnHide,
  useFocusOnPointerDown,
  useFocusOnShow,
  useForceUpdate,
  useId,
  useIds,
  useInterval,
  useLatestRef,
  useMergeRefs,
  useMouseDownRef,
  useOptionalPart,
  useOutsideClick,
  usePanGesture,
  usePointerEvent,
  usePrevious,
  useSafeLayoutEffect,
  useShortcut,
  useTimeout,
  useUnmountEffect,
  useUpdateEffect,
  useWhyDidYouUpdate
};
