// ../../../react-shim.js
import React from "react";

// src/button.tsx
import { useMergeRefs } from "@chakra-ui/react-use-merge-refs";
import {
  chakra as chakra3,
  forwardRef,
  omitThemingProps,
  useStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
var dataAttr = (condition) => condition ? "" : void 0;

// src/button.tsx
import { useMemo as useMemo2 } from "react";

// src/button-context.ts
import { createContext } from "@chakra-ui/react-context";
var [ButtonGroupProvider, useButtonGroup] = createContext({
  strict: false,
  name: "ButtonGroupContext"
});

// src/button-icon.tsx
import { chakra } from "@chakra-ui/system";
import { cloneElement, isValidElement } from "react";
function ButtonIcon(props) {
  const { children, className, ...rest } = props;
  const _children = isValidElement(children) ? cloneElement(children, {
    "aria-hidden": true,
    focusable: false
  }) : children;
  const _className = cx("chakra-button__icon", className);
  return /* @__PURE__ */ React.createElement(chakra.span, {
    display: "inline-flex",
    alignSelf: "center",
    flexShrink: 0,
    ...rest,
    className: _className
  }, _children);
}
ButtonIcon.displayName = "ButtonIcon";

// src/button-spinner.tsx
import { Spinner } from "@chakra-ui/spinner";
import { chakra as chakra2 } from "@chakra-ui/system";
import { useMemo } from "react";
function ButtonSpinner(props) {
  const {
    label,
    placement,
    spacing = "0.5rem",
    children = /* @__PURE__ */ React.createElement(Spinner, {
      color: "currentColor",
      width: "1em",
      height: "1em"
    }),
    className,
    __css,
    ...rest
  } = props;
  const _className = cx("chakra-button__spinner", className);
  const marginProp = placement === "start" ? "marginEnd" : "marginStart";
  const spinnerStyles = useMemo(() => ({
    display: "flex",
    alignItems: "center",
    position: label ? "relative" : "absolute",
    [marginProp]: label ? spacing : 0,
    fontSize: "1em",
    lineHeight: "normal",
    ...__css
  }), [__css, label, marginProp, spacing]);
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    className: _className,
    ...rest,
    __css: spinnerStyles
  }, children);
}
ButtonSpinner.displayName = "ButtonSpinner";

// src/use-button-type.tsx
import { useCallback, useState } from "react";
function useButtonType(value) {
  const [isButton, setIsButton] = useState(!value);
  const refCallback = useCallback((node) => {
    if (!node)
      return;
    setIsButton(node.tagName === "BUTTON");
  }, []);
  const type = isButton ? "button" : void 0;
  return { ref: refCallback, type };
}

// src/button.tsx
var Button = forwardRef((props, ref) => {
  const group = useButtonGroup();
  const styles = useStyleConfig("Button", { ...group, ...props });
  const {
    isDisabled = group == null ? void 0 : group.isDisabled,
    isLoading,
    isActive,
    children,
    leftIcon,
    rightIcon,
    loadingText,
    iconSpacing = "0.5rem",
    type,
    spinner,
    spinnerPlacement = "start",
    className,
    as,
    ...rest
  } = omitThemingProps(props);
  const buttonStyles = useMemo2(() => {
    const _focus = { ...styles == null ? void 0 : styles["_focus"], zIndex: 1 };
    return {
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      justifyContent: "center",
      userSelect: "none",
      position: "relative",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      outline: "none",
      ...styles,
      ...!!group && { _focus }
    };
  }, [styles, group]);
  const { ref: _ref, type: defaultType } = useButtonType(as);
  const contentProps = { rightIcon, leftIcon, iconSpacing, children };
  return /* @__PURE__ */ React.createElement(chakra3.button, {
    disabled: isDisabled || isLoading,
    ref: useMergeRefs(ref, _ref),
    as,
    type: type ?? defaultType,
    "data-active": dataAttr(isActive),
    "data-loading": dataAttr(isLoading),
    __css: buttonStyles,
    className: cx("chakra-button", className),
    ...rest
  }, isLoading && spinnerPlacement === "start" && /* @__PURE__ */ React.createElement(ButtonSpinner, {
    className: "chakra-button__spinner--start",
    label: loadingText,
    placement: "start",
    spacing: iconSpacing
  }, spinner), isLoading ? loadingText || /* @__PURE__ */ React.createElement(chakra3.span, {
    opacity: 0
  }, /* @__PURE__ */ React.createElement(ButtonContent, {
    ...contentProps
  })) : /* @__PURE__ */ React.createElement(ButtonContent, {
    ...contentProps
  }), isLoading && spinnerPlacement === "end" && /* @__PURE__ */ React.createElement(ButtonSpinner, {
    className: "chakra-button__spinner--end",
    label: loadingText,
    placement: "end",
    spacing: iconSpacing
  }, spinner));
});
Button.displayName = "Button";
function ButtonContent(props) {
  const { leftIcon, rightIcon, children, iconSpacing } = props;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, leftIcon && /* @__PURE__ */ React.createElement(ButtonIcon, {
    marginEnd: iconSpacing
  }, leftIcon), children, rightIcon && /* @__PURE__ */ React.createElement(ButtonIcon, {
    marginStart: iconSpacing
  }, rightIcon));
}

// src/button-group.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef2
} from "@chakra-ui/system";
import { useMemo as useMemo3 } from "react";
var ButtonGroup = forwardRef2(function ButtonGroup2(props, ref) {
  const {
    size,
    colorScheme,
    variant,
    className,
    spacing = "0.5rem",
    isAttached,
    isDisabled,
    ...rest
  } = props;
  const _className = cx("chakra-button__group", className);
  const context = useMemo3(() => ({ size, colorScheme, variant, isDisabled }), [size, colorScheme, variant, isDisabled]);
  let groupStyles = {
    display: "inline-flex"
  };
  if (isAttached) {
    groupStyles = {
      ...groupStyles,
      "> *:first-of-type:not(:last-of-type)": { borderEndRadius: 0 },
      "> *:not(:first-of-type):not(:last-of-type)": { borderRadius: 0 },
      "> *:not(:first-of-type):last-of-type": { borderStartRadius: 0 }
    };
  } else {
    groupStyles = {
      ...groupStyles,
      "& > *:not(style) ~ *:not(style)": { marginStart: spacing }
    };
  }
  return /* @__PURE__ */ React.createElement(ButtonGroupProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(chakra4.div, {
    ref,
    role: "group",
    __css: groupStyles,
    className: _className,
    "data-attached": isAttached ? "" : void 0,
    ...rest
  }));
});
ButtonGroup.displayName = "ButtonGroup";

// src/icon-button.tsx
import { forwardRef as forwardRef3 } from "@chakra-ui/system";
import { cloneElement as cloneElement2, isValidElement as isValidElement2 } from "react";
var IconButton = forwardRef3((props, ref) => {
  const { icon, children, isRound, "aria-label": ariaLabel, ...rest } = props;
  const element = icon || children;
  const _children = isValidElement2(element) ? cloneElement2(element, {
    "aria-hidden": true,
    focusable: false
  }) : null;
  return /* @__PURE__ */ React.createElement(Button, {
    padding: "0",
    borderRadius: isRound ? "full" : void 0,
    ref,
    "aria-label": ariaLabel,
    ...rest
  }, _children);
});
IconButton.displayName = "IconButton";
export {
  Button,
  ButtonGroup,
  ButtonSpinner,
  IconButton,
  useButtonGroup
};
